'use client';

const RoutePage = () => {
  return (
    <>
      <h1>route page</h1>
    </>
  );
};

export default RoutePage;
